<template>
  <div class="booking-card" @click="gotoTripDetails">
    <AppThumbnailWithPlaceholder :src="imageUrl" placeholder="city" />
    <div class="booking-card-content">
      <p class="booking-card-date">{{ departDate }} - {{ returnDate }}</p>
      <p style="text-transform: uppercase; align-self: center">
        {{ cityName }}, {{ regionName }}
      </p>
      <!-- <transition name="transition-fill" appear>
        <div
          style="
            background: var(--color-muted);
            height: 2px;
            margin-top: 0.5rem;
            width: 100%;
          "
        >
          <div
            :style="`width: ${tripProgress}%`"
            style="height: 100%; background: var(--color-primary)"
          ></div>
        </div>
      </transition> -->
    </div>
  </div>
</template>

<script>
import { date } from '../../other/utils/helpers/date.js'
import AppThumbnailWithPlaceholder from '../AppThumbnailWithPlaceholder.vue'

const formatDate = timestamp => date.formatUTC('-ms -d, -y', timestamp)

export default {
  name: 'BookingCard',
  props: { trip: Object },
  data() {
    const { departureDateTimestamp, returnDateTimestamp, arrivalCity } =
      this.trip

    return {
      departDate: formatDate(date.getGoTimestamp(departureDateTimestamp)),
      returnDate: formatDate(date.getGoTimestamp(returnDateTimestamp)),
      cityName: arrivalCity.name,
      countryName: arrivalCity.region.country.name,
      regionName: arrivalCity.region.name,
      imageUrl: arrivalCity.media.imageUrl,
    }
  },
  computed: {
    tripProgress() {
      const now = Date.now()
      const start = date.getGoTimestamp(this.trip.departureDateTimestamp)
      const end = date.getGoTimestamp(this.trip.returnDateTimestamp)
      const sinceStart = now - start
      const duration = end - start === 0 ? 60000 : end - start
      const progress = (sinceStart / duration) * 100
      const normalizedProgress =
        progress < 0 ? 0 : progress > 100 ? 100 : progress
      return normalizedProgress
    },
  },
  methods: {
    gotoTripDetails() {
      this.$router.push(
        `/bookings/details?eluderef=${this.trip.eludeReference}`,
      )
    },
  },
  components: { AppThumbnailWithPlaceholder },
}
</script>

<style scoped lang="scss">
.booking-card {
  box-shadow: var(--shadow-app);
  transition: var(--transition-default);
  padding: 1.5rem;
  background: white;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.2rem;
  cursor: pointer;
  border-radius: var(--border-radius-m);
  &:hover {
    background: darker(get('color.white'), 1.5%);
    box-shadow: var(--shadow-l);
  }
}
.booking-card-content {
  display: grid;
  grid-template-rows: auto 1fr;
  align-content: flex-start;
  font-size: 13px;
  font-family: LuciferSans;
}
// .transition-fill-enter-active {
//   transition: all 0.5s cubic-bezier(1, 0.4, 0.2, 0.8);
// }
// .transition-fill-enter {
//   width: 0%;
//   opacity: 0;
// }
</style>
