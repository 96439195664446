<template>
  <transition-group
    v-if="upcomingBookings.length"
    name="dropdown-s"
    tag="div"
    appear
    style="display: grid; gap: 0.5rem"
  >
    <BookingCard
      v-for="(trip, index) in upcomingBookings"
      :id="trip.id"
      :key="trip.id"
      :style="{ transitionDelay: 120 * index + 'ms' }"
      :trip="trip"
    />
  </transition-group>
  <div v-else>
    <transition name="float" appear>
      <div
        style="
          display: grid;
          gap: 1.5rem;
          margin-top: 3rem;
          justify-items: center;
        "
      >
        <div class="x-img image-center"></div>
        <x-subtitle style="text-align: center">{{
          $t('bookings.no-upcoming')
        }}</x-subtitle>
        <p class="fs-15" style="text-align: center">
          {{ $t('bookings.time-to-wander') }}
        </p>
        <x-button
          style="margin-top: 2rem"
          type="large"
          @click="$router.push('/search')"
        >
          {{ $t('bookings.search-trips') }}
        </x-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BookingCard from '../../components-rf/bookings/BookingCard.vue'

export default {
  name: 'BookingsUpcoming',
  components: { BookingCard },
  created() {
    this.getUpcomingBookings()
  },
  methods: mapActions('BookingModule', ['getUpcomingBookings']),
  computed: mapState('BookingModule', ['upcomingBookings']),
}
</script>

<style scoped lang="scss">
.x-img {
  background-image: url('~@/assets/world.png');
  width: 138px;
  height: 140px;
  border-radius: 74px;
}
</style>
